<template>
    <div class="transfer" :style='{width:transferWidth}'>
        <div class="box">
            <div class="left">
                <div class="top">
                    <span class="listName">
                        <el-checkbox v-model="checkAllLeft" :indeterminateLeft="indeterminateLeft"
                         @change="leftCheckAll">区域学院</el-checkbox>        
                    </span>
                    <span class="percent">{{leftStu.length}}/{{leftList.length}}</span>
                </div>
                <div class="bottom">
                    <el-select v-model='areaId' placeholder="请选择区域" @change="changSchool">
                        <el-option v-for="v in orgList" :label="v.name" :data-index="v.id" :value="v.id" :key="v.id"></el-option>
                    </el-select>
                    <ul class="list">
                        <el-checkbox-group v-model="leftStu" @change="leftCheck"> 
                        <li v-for="(v,index) in leftList"  :key="index">                     
                            <el-checkbox  :label="v.shortName" :disabled="v.disabled">
                                {{v.shortName}}
                            </el-checkbox> 
                        </li>                               
                    </el-checkbox-group> 
                    </ul>                                  
                </div>
            </div>
            <!-- 中间部分 -->
            <div class="center">
                <button type="button" :class="leftStu.length!=0?'blue':''"  @click="toRight">
                    <i class="el-icon-arrow-right"></i>
                </button>
            </div>
            <div class="right">
                <div class="top">
                    <span class="listName">
                        已选学院（{{rightList.length}}）
                    </span>
                </div>
                <div class="bottom">
                    <ul class="list">                 
                        <li v-for="(v,index) in rightList"  :key="index" class="delRightLi">
                            <span>{{v.name}}</span>
                            <span @click="delRightStu(index,v.name)">
                                <i class="el-icon-delete"></i>
                            </span>
                        </li>                                             
                    </ul>                                  
                </div>
            </div>

        </div>
    </div>
</template>
<script>

export default {
    props:{
       
        // rightArr:Array,
        transferWidth:{
            default:"578px",
            type:String
        },  
        rightList:{
            type:Array,
            default:()=>{
                return []
            }
        }    
        
    },
    data(){
        return{          
            checkAllLeft:false,         
            leftStu:[],//左边已选的学生
            indeterminateLeft:true, 
            areaId:null,
            leftList:[],
            // rightList:[] ,
            orgList:[],      
        }
    },
    watch:{
        rightList(newVal){
           this.judge() 
           this.$emit('rightValue', newVal)
        }
    },
    mounted(){
        this.getSchoolList()  
    },
    methods: {
        getSchoolList(){
            this.$htp.get_('/tea/org/list').then((res)=>{ 
                this.orgList=res.data
            }) 
        },
     initClass(){
         this.areaId=null;
         this.leftList=[];
         this.rightList=[]
     },
     //选择班级
     changSchool(){
         this.checkAllLeft=false
         this.indeterminateLeft=false
         this.leftList=[]
         this.orgList.forEach((e)=> {
             if(e.id==this.areaId){
                 this.leftList=e.systemOrganizes
             }
         } )
         this.leftList.forEach(e=>{
            e.disabled=false
         })
         this.judge()      
     },
      judge(){
          let arr=[]
          this.leftList.forEach((v)=>{
              this.rightList.forEach(e=>{
                  if(e.name==v.shortName){
                      v.disabled=true
                      arr.push(v.shortName)                   
                  }
              })
          })        
          this.leftStu=arr      
      },
      delRightStu(index,name){
          this.rightList.splice(index,1)
          this.leftList.forEach(e=>{
              if(e.shortName==name){
                  e.disabled=false
              }
          })
         
      },
      toRight(){
          let obj=[]   
          this.leftList.forEach(e=>{
              this.leftStu.forEach(v=>{
                  if(v==e.shortName&&e.disabled==false){
                      obj.push({id:e.id,name:e.shortName,areaId:e.areaId})
                  }
              })
          }) 
          this.rightList.push(...obj) 
           this.judge()
      },
      leftCheckAll(val) {
        let arr=[];
        this.leftList.forEach(e=>{
            arr.push(e.shortName)
        })
        this.leftStu = val ? arr : [];
        this.indeterminateLeft = false;
      },
      leftCheck(value) {
        let checkedCount = value.length;
        this.checkAllLeft = checkedCount === this.leftList.length;
        this.indeterminateLeft = checkedCount > 0 && checkedCount < this.leftList.length;       
      },
    }
}
</script>
<style scoped>
.transfer{
    background-color: #fff;
}
.box{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 316px;
    
}
.left,.right,.center{
    height: 100%;   
}
.left,.right{
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    width: 230px;
}
.center{
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.center button{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    margin-top:10px;
    cursor: pointer; 
    outline: none; 
}
.center button.blue{
    background-color: #409EFF;
}
.center button.blue i{
    color: #fff;
}
.top{
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #DCDFE6;
    border-bottom: 1px solid #ebeef5;
}
.top .percent{
    color: #909399;
    font-size: 12px;
}
.top .listName{
    color: #606266;
    cursor: pointer;
    font-size: 14px;
}
.bottom{
    margin: 10px;
}
.bottom .el-select{
    width: 190px;
}
.bottom .list {
    max-height: 200px;
    overflow: auto;
    padding: 0px;
    margin: 10px 10px;
}
.bottom .list li{
    height: 26px;
    font-size: 14px;
    line-height: 26px;
}
.bottom .list li.delRightLi{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0px 6px;
    box-sizing: border-box;
}
.bottom .list li.delRightLi:hover{
    background-color: #ebeef5;
}
.bottom .list li.delRightLi i{
    color: #409EFF;
    display: none;
}
.bottom .list li.delRightLi:hover i{
    display: block;
}
</style>