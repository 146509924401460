<template>
    <div>
         <el-page-header @back="goBack" :content="title+'双选会'" >
        </el-page-header>
        <div class="topBtn">
            <el-button type="primary" size="small" @click="save"
            icon="iconfont icon-baocun">
                保存</el-button>
        </div> 
        <div class="formBox">
            <el-form :model="form">
            <el-form-item label="双选会名称：" :label-width="formLabelWidth">
                <el-input v-model="form.dcName" clearable placeholder="请输入双选会名称"></el-input>
            </el-form-item>
                <el-form-item label="主办方名称：" :label-width="formLabelWidth">
                <el-input v-model="form.sponsor" clearable placeholder="请输入主办方名称"></el-input>
            </el-form-item>
                <el-form-item label="从办方名称：" :label-width="formLabelWidth">
                <el-input v-model="form.secSponsor" clearable placeholder="请输入从办方名称"></el-input>
            </el-form-item>
            <el-form-item label="双选会时间：" :label-width="formLabelWidth">
                <el-date-picker
                style="width:273px"
                v-model="date1"
                value-format='timestamp'
                type="date"
                placeholder="选择开始日期">
                </el-date-picker>
                <span class="space">至</span>
                <el-date-picker
                style="width:273px"
                v-model="date2"
                value-format='timestamp'
                type="date"
                placeholder="选择结束日期">
                </el-date-picker>
               
            </el-form-item>
                <el-form-item label="区域学校：" :label-width="formLabelWidth">
                    <transferBox ref="transfer" 
                    :rightList='rightArr' 
                    v-on:rightValue='rightValue'
                ></transferBox>
            </el-form-item>  
            <el-form-item label="双选会内容：" :label-width="formLabelWidth" clearable>
                <el-input type="textarea" v-model="form.doubleChooseIntroduce" :rows="3" placeholder="请输入双选会内容"></el-input>
            </el-form-item>
        </el-form>
        </div>
          
    </div>
</template>
<script>
import transferBox from '../../components/transfer'
export default {
    components:{transferBox},
    data () {
        return {
            form:{},
            date1:'',
            date2:'',
            formLabelWidth:'100px',
            rightArr:[],
            areaId:null,
            orgIds:null,
            choseId:null,
            teaId:null,
            titleList:['新增','编辑'],
            title:'新增'
        }
    },
    methods:{
        goBack(){
            this.$router.push({path:'/doubleManage'})
        },
        save(){
            let {dcName,doubleChooseIntroduce,sponsor,secSponsor}=this.form
            let obj={
                dcName,doubleChooseIntroduce,sponsor,secSponsor,
                startTime:this.date1,
                endTime:this.date2,
                orgIds:this.orgIds,
                areaIds:this.areaId,
                createdBy:this.teaId
            }
            
            this.$confirm('此操作将保存双选会，请检查好双选会相关信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(this.title=='编辑'){
                    obj.id=this.choseId
                    this.$htp.put_('/tea/dc/update',obj,true).then(()=>{
                        this.$router.push({path:"/doubleManage"})
                    }) 
                }else{
                    this.$htp.post_('/tea/dc/add',obj,true).then(()=>{
                        this.$router.push({path:"/doubleManage"})
                    }) 
                }
                
            }).catch(() => {});
           
        },
        rightValue(rightValue){
            let orgIds=[],areaId=[]
            rightValue.forEach(e=>{
                orgIds.push('【'+e.id+'】')
                areaId.push( '【'+e.areaId+'】')
            })
            this.orgIds=orgIds.join()
            let areaIdArr = areaId.filter(function (item, index, self) {
                return self.indexOf(item) == index;
            });
            this.areaId=areaIdArr.join()
       
        },
    },
    mounted(){
        let obj=this.$route.params.v;
        let code=this.$route.params.titleCode
        this.title=this.titleList[code]
        this.teaId=sessionStorage.getItem('id')
        if(obj){
            const {id,dcName,doubleChooseIntroduce,endTime,startTime,sponsor,
            secSponsor,orgAreas}=obj
            let arr=[],areaObj={}
            orgAreas.forEach(v=>{
                
               v.systemOrganizes.forEach(e=>{
                   areaObj={}; 
                   areaObj.areaId=e.areaId
                   areaObj.name=e.name
                   areaObj.id=e.id
                   arr.push(areaObj)  
               })
                 
            })
            this.rightArr=arr
            this.date1=new Date(startTime).getTime()
            this.date2=new Date(endTime).getTime()
            
            this.choseId=id
            this.form={dcName,doubleChooseIntroduce,sponsor,secSponsor}
        }
        
    }
}
</script>
<style lang="scss" scoped>
.space{
    margin: 0px 20px;
}
.topBtn{
    margin-top: 20px;
}
.formBox{
    margin-top: 20px;
    width: 700px;
}
</style>